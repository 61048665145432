html {
    @media screen and (max-width: 767px) {
        scroll-behavior: smooth;
    }
}

body {
    background-color: #111827;
}

.App {
    min-height: 100vh;
    .Toastify__toast--success {
        background: #5961ab;
    }
}

.discord-st0 {
    fill: #fff;
}

hr.styled-hr {
    border-top: 1px solid #0f131a;
}

a.nav-link {
    transition: 0.3s;
    &:hover {
        color: rgba(255,255,255,0.8)
    }
}

/* Disabled link */
div.nav-link {
    cursor: default;
}

.nav-link.nav-link-hamburger {
    &:hover {
        background-color: rgba(255,255,255,0.1)
    }
}

.nav-link-container-active .nav-link.nav-link-hamburger {
    background: #06080e;
    color: #9ca3af;
    cursor: default;
}

.bg-header {
    background-color: #0b0f19;
}


.hero-button-container {
    @media screen and (max-width: 512px) {
        > div {
            flex: 1 1 100%;
        }
    }

    > div {
        margin-bottom: 1rem;
        min-width: 210px;
        max-width: 360px;
    }

    .hero-button-yellow {
        background: none;
        color: #e7aa1e;
        font-weight: 500;
        transition: 0.3s;
        border: 1px solid;
        &:hover,
        &:focus {
            background: #36342d;
        }
    }
    
    .hero-button-discord {
        color: #fff;
        background: none;
        border: 1px solid #fff;
        transition: 0.3s;
        &:hover,
        &:focus {
            background: #40454f;
            outline: none;
        }
        
    }
}



.app-burger {
    .bm-burger-button {
        position: relative;
        width: 36px;
        height: 30px;
    }
    
    .bm-burger-bars {
        background: #fff;
    }
    
    .bm-burger-bars-hover {
        background: rgba(255,255,255,0.8);
    }
    
    .bm-cross-button {
        height: 36px!important;
        width: 36px!important;
    }
    
    .bm-cross {
        background: #bdc3c7;
    }
    
    .bm-menu-wrap {
        position: fixed;
        right: 0px;
        z-index: 1100;
        width: 300px;
        height: 100%;
        transition: all 0.5s ease 0s;
        top: 0px;

    }
    
    .bm-menu {
        box-shadow: -20px -1px 20px 9px rgba(0, 0, 0, .34);
        background: #0b0f19;
        padding: 2.5em 0;
        font-size: 1.15em;
        border-left: 2px solid #374151;
    }
    
    .bm-morph-shape {
        fill: #373a47;
    }
    
    .bm-item-list {
        color: #fff;
        padding: 0.8em 0;
    }
    
    .bm-item {
        display: inline-block;
    }
    
    .bm-overlay {
        background: rgba(0, 0, 0, 0.3);
    }
}

.flavor-container {
    @media screen and (max-width: 1279px) {
        bottom: -23px;
    }
    @media screen and (min-width: 1280px) {
        bottom: -23px;
    }
    
}

.bg-flavor {
    background-color: #101623;
}

.results-placeholder-container {
    flex: 1 1 auto;
    
    .placeholder-content {
        position: relative;
        bottom: -13%;
    }

    .placeholder-flavor {
        background-color: #111827;        
        @media screen and (max-width: 1279px) {
            height: 155px;
            background-image:url('./resources/waves-stroked-sm.svg');
            resize: horizontal;
            background-size: 100% 100%;
        }
        @media screen and (min-width: 1280px) {
            height: 190px;
            background-image:url('./resources/waves-stroked.svg');
        }
    }

    .placeholder-flavor-head {
        height: 4rem;
        background-color: #111827;
    }

    .placeholder-filler {
        flex: 1 1 auto;
        background-color: #101623;
    }
}

.sk-chase {
    width: 120px;
    height: 120px;
    position: relative;
    animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; 
    animation: sk-chase-dot 2.0s infinite ease-in-out both; 
}

.sk-chase-dot:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    border-radius: 100%;
    animation: sk-chase-dot-before 2.0s infinite ease-in-out both;
    @media screen and (max-width: 639px) {
        background-color: #fff;
    }
    @media screen and (min-width: 640px) {
        background-color: #182035;
    }
}

.sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
.sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }

@keyframes sk-chase {
    100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot {
    80%, 100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot-before {
    50% {
        transform: scale(0.4); 
    } 100%, 0% {
        transform: scale(1.0); 
    } 
}

.button-spinner {
    .sk-chase {
        width: 1rem;
        height: 1rem;
    }
    .sk-chase-dot:before {
        background-color: #fff;
        border-radius: 100%;
        animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
    }
}

.footer-container {
    @media screen and (min-width: 1024px) {
        border-top: 1px solid #1b253c;
    }
}

.mr-1\/2 {
    margin-right: 0.10rem;
}

.hero-container {
    @media screen and (min-width: 1280px) {
        min-height: calc(100vh - 112px);
    }
}

.blob-container {
    @media screen and (min-width: 1280px) {
        background:
            url('./resources/blob2.svg') no-repeat
    }
}

.break {
    flex-basis: 100%;
    height: 0;
}
